// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-masinga-cottage-js": () => import("./../src/pages/masinga-cottage.js" /* webpackChunkName: "component---src-pages-masinga-cottage-js" */),
  "component---src-pages-mathemba-house-js": () => import("./../src/pages/mathemba-house.js" /* webpackChunkName: "component---src-pages-mathemba-house-js" */),
  "component---src-pages-mikas-tree-js": () => import("./../src/pages/mikas-tree.js" /* webpackChunkName: "component---src-pages-mikas-tree-js" */),
  "component---src-pages-moramba-cottage-js": () => import("./../src/pages/moramba-cottage.js" /* webpackChunkName: "component---src-pages-moramba-cottage-js" */),
  "component---src-pages-reviews-js": () => import("./../src/pages/reviews.js" /* webpackChunkName: "component---src-pages-reviews-js" */),
  "component---src-pages-villa-vega-js": () => import("./../src/pages/villa-vega.js" /* webpackChunkName: "component---src-pages-villa-vega-js" */)
}

